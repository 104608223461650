import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import textPT from '../../assets/data/translate/um-super-app/text-pt.json'
import backgrounds from '../../assets/img/backgrounds-main-hero'
import * as S from './style'

const HeroSuperAppMain = React.forwardRef((props: { setIsOpen: Function }, ref: React.Ref<HTMLDivElement>) => {
  const { title, CTA }: ISlideScrollSections = textPT
  const windowWidth = useWidth(300)
  const { setIsOpen }: { setIsOpen: Function } = props
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.ModelsSectionBackground
      key='TESTE'
      className='d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection1.mobile}
      backgroundImage={backgrounds.backgroundHomeSection1.desktop}
      ref={ref}
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='ml-md-5 pl-xl-5'>
              <S.TitleExtraLarge
                className={`${windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'} fw-400 f-sora`}
                dangerouslySetInnerHTML={{ __html: title }}
                aria-label={title}
              />
              <div className='my-3'>
                <S.HerobannerTag className='mb-0'>E da Tatá também.</S.HerobannerTag>
              </div>
              <div>
                <S.CTA
                  as='button'
                  aria-label={CTA.text}
                  className={windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'}
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: 'O Super App da sua vida financeira',
                      element_action: 'click button',
                      element_name: CTA.text,
                    })
                  }}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={windowWidth < WIDTH_MD ? '#FFFFFF' : '#161616'} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
})

export default HeroSuperAppMain
